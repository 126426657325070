<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="role.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="role.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="Administradora" />
      <BorderSelect
        v-model="role.data.admin"
        label="Administradora"
        :options="role.adminList"
      />
      <FormError />
    </div>

    <BasicLabel label="Módulos *" />
    <SelectionTree
      :data="role.tree"
      v-model:selectionKeys="role.data.selected"
      :checked="role.checked"
    />
    <FormError
      :show="role.rules.selected"
      message="Seleccione como mínimo una funcionalidad"
    />
    <div class="container-submit">
      <PrimaryButton label="Guardar" :click="onSave" />
    </div>
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import SelectionTree from "../../widgets/tree/SelectionTree.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    OutlinedTextArea,
    FormError,
    PrimaryButton,
    SelectionTree,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const role = reactive({
      tree: [],
      checked: "checkbox",
      adminList: [
        { id: true, name: "Sí" },
        { id: false, name: "No" },
      ],
      data: {
        id: "",
        name: "",
        admin: "",
        selected: {},
      },
      rules: {
        name: false,
        selected: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!role.data.name) {
        role.rules.name = true;
        valid = false;
      }
      if (!Object.keys(role.data.selected).length && !role.data.admin) {
        role.rules.selected = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: role.data.id,
        companyId: store.state.auth.user.company,
        name: role.data.admin ? "-1" : role.data.name.toLowerCase(),
        description: role.data.name,
        functionalities: [],
      };

      if (role.data.admin) {
        request.functionalities = store.state.auth.functionalities.map(
          (item) => item.id
        );
      } else {
        request.functionalities = Object.keys(role.data.selected)
          .filter((key) => !store.state.auth.modules.includes(key))
          .map((key) => +key);
      }

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(actions.rolesActions.update, request);
      } else {
        response = await store.dispatch(actions.rolesActions.create, request);
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedRoles", []);
        router.push("/perfiles/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const getSelectedKeys = (list) => {
      role.tree.forEach((node) => {
        const selectedLeafs = list
          .filter((item) => item.module.name === node.key)
          .map((item) => item.id);
        if (selectedLeafs.length) {
          if (selectedLeafs.length === node.children.length) {
            role.data.selected[node.key] = { checked: true };
          }
          selectedLeafs.forEach(
            (key) => (role.data.selected[key] = { checked: true })
          );
        }
      });
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/perfiles/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      store.state.auth.modules.forEach((item) => {
        const obj = {
          key: item,
          label: item,
          children: store.state.auth.functionalities
            .filter((el) => el.module.name === item)
            .map((el) => ({ key: el.id, label: el.name })),
        };
        role.tree.push(obj);
      });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.role.selectedRoles[0];
        role.data.id = selected.id;
        role.data.name = selected.description;
        getSelectedKeys(selected.functionalities);
      }
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (role.data.name) role.rules.name = false;
      if (Object.keys(role.data.selected).length || role.data.admin)
        role.rules.selected = false;

      if (role.data.admin) role.checked = "";
      else role.checked = "checkbox";
    });

    return { role, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 0;
}
.container-submit {
  margin-top: 4rem;
}
</style>
